import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';
import "./assets/font/iconfont.css";
import '@vant/touch-emulator';
import 'swiper/css/swiper.css';

Vue.config.productionTip = false
import { Button,Swipe, SwipeItem,Sticky,Circle ,Tabs,Tab,Search, Popup,Collapse, CollapseItem, TreeSelect, Lazyload,Field  } from 'vant';
Vue.use(Button).use(Swipe).use(SwipeItem).use(Sticky).use(Circle).use(Tabs).use(Tab).use(Search).use(Popup).use(Collapse).use(CollapseItem).use( TreeSelect).use( Lazyload).use(Field);
new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
