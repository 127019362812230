<template>
	<header class="headall" @mousewheel="runt()">

		<div class="head" @mousemove="clearbox()" @mouseout="clearboxnone()" v-show="this.$store.state.show">
			<!-- 抬头导航栏 -->
			<div style="width: 100%;">
				<div class="v-head" ref="head">
					<div class="v-h-all" ref="vhead" v-show="head">
						<div class="v-h-right"><a href="https://grow.163.com/" class="h-l-a">网易智企</a></div>
						<div class="v-h-left">
							
							<div><a href="https://yunxin.163.com/" class="h-l-a">网易云信</a></div>
							<div><a href="https://b.163.com/home/product/survey" class="h-l-a">网易定位</a></div>
							<div><a href="https://b.163.com/home/product/scrm" class="h-l-a">网易互客</a></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="v-foot" ref="ahead" v-show="this.$store.state.show">
			<div class="v-h-foot">
				<div class="v-h-f-left" @click="back()"><img src="../../assets/img/网易七鱼log .jpg" alt="">
				</div>
				<div class="v-h-f-mid">
					<li @mousemove="produ()" @mouseleave="clear()"><span>产品</span><i class="iconfont icon-arrow-down-bold"
							style=""></i>
					</li>
					<li @mousemove="schem()" @mouseleave="clear()"><span>解决方案</span><i class="iconfont icon-arrow-down-bold"
							style=""></i></li>
					<li @mousemove="buys()" @mouseleave="clear()"><span>购买</span><i class="iconfont icon-arrow-down-bold"
							style=""></i></li>
					<li @click="serve()"><span>服务支持</span><i></i></li>
					<li @click="reward()"><span>购买奖励</span><i></i></li>
					<li @mousemove="helps()" @mouseleave="clear()"><span>帮助</span><i class="iconfont icon-arrow-down-bold"
							style=""></i></li>
				</div>
				<div class="v-h-f-right">
					<div class="free-try" @click="freetry()">免费试用</div>
				</div>
			</div>
			<transition name="fade">
				<div class="product" v-show="reproduct != null" @mouseenter="inter()" @mouseleave="out()">
					<div v-for="(item, index) in this.rescheme" class="producs"
						@click="jumper(item.webs, item.net, index, item.name)">
						<div>{{ item.name }}</div>
						<div style="font-size:12px;margin-top:5px;color:rgb(170, 170, 170)">{{ item.text }}
						</div>
					</div>
				</div>
			</transition>
		</div>
	</header>
</template>
<script>
import Swiper from 'swiper'
export default {
	data() {
		return {
			i: 0,
			trail: '',
			change: null,
			head: true,
			react: false,
			show: true,
			reproduct: null,
			leave: false,
			rescheme: null,
			clss: null,
			name: "<br>",
			set: null,
			move: true,
			time: '',
			index: 0,
			clientHeight: window.scrollY,
			pic: require('../../assets/img/body（0）.png'),
			pick: [{
				title: "文本机器人",
				body: "7*24h多场景接入，精准识别访客需求并秒级响应。",
				foot: [{ one: "用户问题预测" }, { one: "先进的对话能力" }, { one: "转人工引擎" }, { one: "知识库运营" }],
				color: "#318c85",
				titlecolor: "#1f6660",
			}, {
				title: "语音机器人",
				body: "呼入呼出两大场景，依靠ASR/NLP/TTS打造沉浸式对话体验。",
				foot: [{ one: "ASR语音识别" }, { one: "NLP语义理解" }, { one: "海量语料" }, { one: "多线路对接" }],
				color: "#1893bb",
				titlecolor: "#006080",
			}, {
				title: "坐席辅助",
				body: "知识库辅助人工完成问题解答，人工反馈知识库持续优化。",
				foot: [{ one: "知识点推荐" }, { one: "场景分析" }, { one: "坐席数据统计" }, { one: "访客情绪识别" }],
				color: "#a67f63",
				titlecolor: "#705141",
			}, {
				title: "客户之声",
				body: "会话关键信息提取，访客异常情绪识别，时刻优化用户体验。",
				foot: [{ one: "情绪分析" }, { one: "低评分析" }, { one: "热词分析" }, { one: "场景分析" }],
				color: "#5360a7",
				titlecolor: "#333b66",
			}],
			product: [
				{ name: "在线客服", text: '在线服务，解决问题挖掘价值', webs: "online" },
				{ name: "在线机器人", text: '更懂你的七鱼机器人', webs: 'bot' },
				{ name: "呼叫中心", text: '云呼叫系统，安全稳定更贴心', webs: "call" },
				{ name: "智能工单系统", text: '解决跨部门协同问题', webs: "smart" },
				{ name: "智能质检", text: '高效智能解析，精确挖掘数据', webs: 'intell' },
				{ name: "视频客服", text: '促进销售转化，提高问题解决率', webs: 'video' },
				{ name: "企微客服", text: '提深企微服务效率和质量', webs: 'custer' },
				{ name: "数据大屏", text: '炫酷的数据可视化展示', webs: 'data' },
				{ name: "智能外呼", text: '智能多轮自动外呼', webs: 'outcall' },
				{ name: "售前留资机器人", text: '精准获取目标线索', webs: "mebot" },
				{ name: "平台电商版", text: '聚焦电商平台，协作提升服务质量', webs: 'platform' },
				{ name: "海外版", text: '海内外全渠道接入，一站式出海服务，助力全球业务增长', webs: 'sea' }],
			help: [
				{ name: "开发指南" },
				{ name: "下载中心" },
				{ name: "帮助中心" },
				{ name: "轻学院" },
				{ name: "博客" },
				{ name: "服务条款" },
				{ name: "关于我们" },
			],
			authen: [
				{ name: "ISO27001信息安全管理", nem: "体系国际认证", pic: require('../../assets/img/DNV.png') },
				{ name: "ISO27701个人隐私信息安全", nem: "管理体系认证 ", pic: require('../../assets/img/DNV.png') },
				{ name: "CSA STAR云安全管理", nem: "体系认证", pic: require('../../assets/img/DNV.png') },
				{ name: "ISO9001质量管理", nem: "体系认证", pic: require('../../assets/img/iso.png') },
				{ name: "ISO20000IT服务管理", nem: "体系认证", pic: require('../../assets/img/iso.png') },
				{ name: "CMMI3软件能力成熟度", nem: "模型集成认证", pic: require('../../assets/img/cmmi.png') },],
			phone: [{
				item: "电话: 17097402623"
			}, {
				item: "商务合作：bd@qiyukf.com"
			}, {
				item: "渠道合作：qudao@qiyukf.com"
			},],
			scheme: [{ name: "电商行业解决方案", text: '电商全场景智能化解决方案', webs: "scheme" },
			{ name: "教育行业解决方案", text: '获客咨询售后服务一站式解决', webs: 'tech' },
			{ name: "智慧政务服务解决方案", text: '推动政务服务平台数字化转型', webs: "gover" },
			{ name: "生鲜电商解决方案", text: '围绕生鲜消费者服务需求推动智能体验', webs: "fresh" },
			{ name: "时尚鞋服解决方案", text: '赋能总部-门店协同，构筑有温度、懂客户的客户体验', webs: "fasion" },
			{ name: "连锁药店解决方案", text: '帮助连锁药店构建“强关系型”会员运营体系，强化用户品牌心智，助力长期营收增长', webs: "medic" },
			{ name: "家电解决方案", text: '营销服务一体化，助力家电行业新增长', webs: "mech" },
			{ name: "汽车全渠道解决方案", text: '打造全场景，全链路的车主营销服务平台', webs: "car" },
			{ name: "游戏出海解决方案", text: '一站式出海服务，打造五星级玩家体验', webs: "game" },],
			helpe: [{ name: "开发指南", text: '开发接口文档大全', webs: null, net: 'http://docs.qiyukf.com/' },
			{ name: "下载中心", text: '七鱼工作台，访问SDK', webs: "down" },
			{ name: "帮助中心", text: '常见问题自助查询', webs: null, net: 'http://help.qiyukf.com/' },
			{ name: "轻学院", text: '帮助每一家企业成长', webs: null, net: 'http://qi.163.com/college' },
			{ name: "博客", text: '七鱼客户交流社区', webs: null, net: 'http://blog.qiyukf.com/' },
			{ name: "关于我们", text: '七鱼客服介绍', webs: "intro", net: '' },],
			
			buy: [{ name: "在线客服", text: '在线服务，解决问题挖掘价值', webs: "buy" },
			{ name: "在线机器人", text: '更懂你的七鱼机器人', webs: 'buy' },
			{ name: "呼叫中心", text: '云呼叫系统，安全稳定更贴心', webs: "buy" },
			{ name: "智能工单系统", text: '解决跨部门协同问题', webs: "buy" },
			{ name: "数据大屏", text: '炫酷的数据可视化展示', webs: 'buy' },
			{ name: "外呼机器人", text: '智能多轮自动外呼', webs: 'buy' },]

		}
	},
	onLoad() {

	},

	methods: {
		
		back() {
			if (window.location.href != this.$route.query.id) {
				this.$router.push({ name: 'index', query: { id: window.location.href } })
			}
		},
		delet() {
			this.$refs.deleteall.style = "display:none;"
		},
		freetry() {
			this.$router.push({ name: 'freetry' })
		},
		out() {
			this.reproduct = null
		},
		reward() {
			if (window.location.href != this.$route.query.id) {
				this.$router.push({ name: 'reward', query: { id: window.location.href } })

			}

		},
		clearbox() {
			this.move = false
		},
		clear() {
			this.react = false
			setTimeout(() => {
				if (this.react == false) {
					this.reproduct = null
				}
			}, 100)
		},
		inter() {
			this.react = true
		},
		clearboxnone() { this.move = true },

		produ() {
			this.reproduct = this.product
			this.rescheme = this.product

			this.react = true
		},
		schem() {
			this.reproduct = this.scheme
			this.rescheme = this.scheme
			this.react = true
		},
		serve() {
			if (window.location.href != this.$route.query.id) {
				this.$router.push({ name: 'serve', query: { id: window.location.href } })

			}
		},
		buys() {
			this.reproduct = this.buy
			this.rescheme = this.buy
			this.react = true
		},
		helps() {
			this.reproduct = this.helpe
			this.rescheme = this.helpe
			this.react = true
		},
	
		jumper(net, web, i, name) {

			this.$store.state.num = i

			if (net == null) {
				window.location.href = web
			}
			if (window.location.href != this.$route.query.id) {
				this.$router.push({ name: net, query: { id: window.location.href, index: i } })

			}

		}
	},
	beforeDestroy() {
		this.set = null
		clearInterval()
	},
	mounted() {
		this.set = setInterval(() => {
            if(window.scrollY>0){
				this.$refs.head.style.display='none'
				this.$refs.ahead.style.backgroundColor='white'
				this.$refs.ahead.style.borderBottom='1px solid rgb(230, 230, 230)'
			}else{
				this.$refs.head.style.display="flex"
				this.$refs.ahead.style.backgroundColor='transparent'
				this.$refs.ahead.style.borderBottom='none'
			}
		}, 10)
	
	},
}
</script>

<style lang="scss">


.headall {
	position: fixed;
	z-index: 100;
	width: 100%;
	animation: my 0.6s;
	-webkit-animation: my 0.6s;

	.fade-leave,

	.fade-enter-to {
		opacity: 1;
	}

	.fade-leave-active {
		transition: all 0.3s;
	}

	.fade-enter-active {
		transition: all 0.3s;
	}

	.fade-leave-to,
	.fade-enter {
		opacity: 0;
	}


	.producs {
		width: 20%;
		padding: 20px 30px;
		margin-bottom: 10px;
	}

	.v-h-f-mid li {
		display: flex;
	}

	.g-new-sitemap-footer {
		padding: 16px;
		text-align: center;
		font-size: 12px;
		line-height: 1;
		color: rgba(26, 34, 51, 0.5);
		box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
	}
   
	.product {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		cursor: pointer;
		background-color: white;
		min-width: 1200px;
	}

	.product div:hover {
		color: #4382ff;
	}

	.prd dt dd {
		display: block;
		width: 220px;
	}


	.l-p-dd div {
		font-size: 12px;
	}

	.auth {
		width: 340px !important;
	}

	.choose {
		width: 1200px;
		margin: 0 auto;
		margin-top: 40px;
		margin-bottom: 80px;
	}

	.choose img {
		width: 1200px;
	}

	.choose div {
		font-size: 32px;
		font-weight: 600;
		width: 1200px;
		text-align: center;
		margin-bottom: 20px;
	}

	.v-foot {
		width: 100%;
		background-color: transparent;
		z-index: 1;
	}

	.v-foot:hover {
		background-color: white;
	}

	.lists dt {
		margin-bottom: 24px;
		line-height: 24px;
		font-weight: 600;
		font-size: 18px;
		cursor: pointer;
	}

	.l-a-dd {
		display: flex;
		height: 50px;

	}

	.add {
		width: 96px !important;
	}

	.itemcc {
		height: 40px;
		margin-top: 3px;
	}

	.ddimg img {
		width: 48px;
		height: 49px;
	}

	.ddimg {
		margin-right: 12px;
	}

	.list dd {
		line-height: 18px;
		font-size: 14px;
		font-weight: 400;
		color: #1a2233;
		margin-bottom: 15px;
		text-align: left;
		margin-left: 0;
		cursor: pointer;
	}

	.list {
		width: 220px;
	}

	.lists {
		display: flex;
	}

	.g-new-sitemap-content {
		margin: 0 auto;
		padding: 60px 0 40px;
		width: 1200px;
	}

	.bot-bottom {
		width: 100%;
		background-color: #cce4ff;
		height: 638px;
		margin-top: -30px;
	}

	.ph-free {
		position: absolute;
		top: 6px;
		right: 6px;
		padding: 12px 24px 12px 24px;
		font-size: 16px;
		border-radius: 24px;
		display: inline-block;
		cursor: pointer;
		line-height: 150%;
		color: #fff;
		font-weight: 400;
		background-color: #1861f2;
		overflow: hidden;
	}

	.phonenumber {
		position: relative;
	}

	.phonenumber input {
		display: block;
		padding: 16px 24px;
		box-sizing: border-box;
		width: 480px;
		line-height: 24px;
		font-size: 16px;
		border: none;
		border-radius: 28px;
		border: 2px solid transparent;
		position: relative;
	}

	.blue {
		color: #39f;
	}

	.g-n-title {
		margin-bottom: 32px;
		line-height: 150%;
		font-size: 32px;
		font-weight: 600;
		color: transparent;
	}

	.g-new-trial {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		width: 1200px;
		height: 240px;
		background-color: #1a2233;
		border-radius: 16px;
	}

	.m-m-b-r-down {
		padding: 16px 24px;
		margin-top: 8px;
		border-radius: 8px;
		cursor: pointer;

	}

	.m-m-b-r-down div {
		text-align: left;
	}

	.m-m-t-b-title {
		font-size: 20px;
		font-weight: 600;
		height: 30px;
		line-height: 30px;
	}


	.m-m-t-b-foot {
		display: none;
		margin-top: 16px
	}

	.m-m-t-b-body {
		margin-top: 8px;
		font-size: 14px;
		color: #676b73;



	}

	.m-m-t-b-foot {
		display: flex;

	}

	.m-m-t-b-right {
		width: 540px;
		text-align: center;
	}

	.v-h-f-mid li:hover {
		color: #538cff;
	}

	.m-m-t-body {
		width: 1200px;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		margin-bottom: 60px;

	}

	.m-m-t-b-left {
		width: 620px;
		overflow: hidden;
		height: 504px;

	}

	.m-m-t-b-left img {
		width: 620px;
		height: 504px;
		display: block;
	}

	.m-m-t-h2 {
		margin-bottom: 60px;
		line-height: 48px;
		font-weight: 600;
		font-size: 32px;
		text-align: center;
	}

	.m-m-t-h2 span {
		color: #1861f2;
	}

	.m-homePage-touch {
		margin-top: 120px;
		text-align: center;
		margin-bottom: 60px;
	}

	.m-h-d-body {
		height: 105px;
		line-height: 150%;
		text-align: left;
		font-size: 14px;
		font-weight: 400;
		color: #676b73;
		width: 242px;
		margin: 0 auto;
		margin-top: 16px;
	}

	.m-h-d-foot div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 12px 12px 0;
		padding: 3px 12px;
		width: 115px;
		line-height: 20px;
		font-size: 14px;
		color: #1a2233;
		border: 1px solid transparent;
		border-radius: 14px;
		box-sizing: border-box;
		text-align: left;
		background-color: #dce6fa;
		width: 140px;


	}

	.m-h-d-foots div:hover {
		background-color: #bcd2ff;
		cursor: pointer;
	}

	.m-h-d-foot div:hover {
		background-color: #bcd2ff;
		cursor: pointer;
	}

	.m-h-d-foots {
		width: 242px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 10px;
	}

	.m-h-d-foot {
		width: 242px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 10px;
	}

	.m-h-d-foots div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 12px 0;
		padding: 3px 12px;
		width: 115px;
		line-height: 20px;
		font-size: 14px;
		color: #1a2233;
		border: 1px solid transparent;
		border-radius: 14px;
		box-sizing: border-box;
		text-align: left;
		background-color: #dce6fa;
		width: 115px;
	}

	.m-h-down {
		height: 240px;
		width: 282px;
		margin-top: -12px;
		padding-top: 24px;
		background-color: transparent;
		z-index: 2;
		border-radius: 12px;
	}




	.m-h-d-head {
		display: flex;
		width: 242px;
		margin: 0 auto;
		height: 34px;

	}

	.m-h-d-head div {
		height: 34px;
		line-height: 34px;
		font-size: 20px;
		font-weight: 600;
	}

	.m-h-down img {
		width: 34px;
		height: 34px;
		margin-right: 12px;
	}

	.m-h-img img {
		width: 100%;
		z-index: -1;
	}


	.m-h-body>div {
		height: 435px;
		width: 282px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2), 0px 8px 15px -8px rgba(25, 34, 51, 0.1);
		border-radius: 12px;
	}

	.m-h-img {
		z-index: -1;
	}

	.m-free {

		padding: 12px 24px 12px 24px;
		font-size: 16px;
		border-radius: 24px;
		display: inline-block;

		line-height: 150%;
		color: #fff;
		font-weight: 400;
		background-color: #1861f2;
		overflow: hidden;
		cursor: pointer;
	}

	.m-free:hover {
		background-color: #7ba7ff;
	}

	.m-h-mid {
		line-height: 48px;
		font-weight: 600;
		font-size: 32px;
		text-align: center
	}

	body {
		margin: 0;
		padding: 0;
	}

	.all {
		min-width: 1200px;
		width: 100%;
	}

	.head {
		width: 100%;
		min-width: 1200px;
		z-index: 1;
		max-height: 90px;
	}

	.v-head {
		width: 100%;
		display: block;
		background-color: transparent;
		position: relative;
		top: 0;
		width: 100%;
		min-width: 1200px;
		z-index: 10;
	}

	.v-head:hover {
		border: none;
	}



	.swiper-pagination-bullet {
		width: 40px !important;
		height: 4px !important;
		border-radius: 10px !important;
		background-color: #8e9091 !important;

	}

	.swiper-pagination {
		text-align: left !important;
		margin-left: 10%;
		top: 460px;
		width: 70%;
	}



	.v-h-all {
		width: 94%;
		height: 28px;
		display: flex;
		margin: 0 auto;
		justify-content: space-between;

	}

	.v-h-left {
		display: flex;
		width: 40%;

	}

	.h-l-a {
		font-size: 12px;
		text-align: center;
		text-decoration: none;
		color: rgba(26, 34, 51, 0.5);
		height: 16px;
		margin-top: 6px;
		display: block;
		box-sizing: border-box;
	}

	.v-h-left div {
		width: 25%;
		text-align: center;
		display: block;
		box-sizing: border-box;
	}

	.v-h-foot {
		width: 94%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		min-width: 1200px;
	}

	.v-h-f-left {

		height: 30px;
		margin-top: 15px;
		cursor: pointer;
	}

	.v-h-f-left img {

		height: 30px;

	}



	.a-b-all {
		margin: 0 auto;
		color: #fff;
		font-size: 24px;
		display: flex;
		justify-content: space-between;
		width: 94%;
		margin: 0 auto;

	}

	.a-b-left {
		display: flex;
		align-items: flex-start
	}

	.b-try {
		display: block;
		background: #337eff;
		border-radius: 5px;
		width: 144px;
		border: 0;
		font-size: 16px;
		color: #fff;
		text-align: center;

		text-decoration: none;

	}

	.b-x {
		color: #fff;
		vertical-align: top;
		margin-left: 15px;
		position: relative;
		top: -15px;
		text-decoration: none;
		cursor: pointer;
	}

	.v-h-foot {
		height: 60px;

		top: 0;
		width: 94%;
		background-color: transparent;
		border-bottom: 1px solid transparent;
		z-index: 99;
		display: flex;

	}

	.v-h-f-mid {

		min-width: 300px;
		display: flex;
		text-decoration: none;
		list-style: none;
	}

	.v-h-f-mid li {
		height: 60px;
		text-align: center;
		line-height: 60px;
		cursor: pointer;
		padding: 0 20px;
	}

	.v-h-f-mid li span {
		font-size: 16px;
	}

	.v-h-f-mid li i {
		font-size: 16px;
	}

	.v-h-f-right {
		height: 60px;
	}

	.free-try {
		padding: 6px 16px;
		line-height: 1.2;
		font-size: 14px;
		letter-spacing: 0;
		border-radius: 6px;
		margin-top: 15px;
		background-color: #397bff;
		color: white;
		cursor: pointer;
	}

	.free-try:hover {
		background-color: #83adff;
	}

	.swipers {
		z-index: -1;
		width: 100%;
		overflow: hidden;

	}

	

	.swper {
		width: 100%;
		min-width: 1200px;
		height: 580px;
		overflow: hidden;

	}

	.swiper {
		width: 100%;
		height: 100%;
	}

	.swiper-pagination {
		width: 80% !important;
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}



	.c-img {
		width: 50%;

	}

	.cust-img {
		width: 100%;
		height: auto !important;
		margin-top: 100px;
	}

	.cust-swpier {
		width: 210px;
		height: 32px;
		overflow: hidden;
	}
	.cust-body {
		font-size: 24px;
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
	}

	.cust-title {
		font-weight: 600;
		font-size: 48px;
		text-align: left;
	}


	.cust-bottom {
		display: flex;
		font-size: 20px;
		margin-top: 35px;
	}

	.c-auto {
		display: flex;
		line-height: 54px;
		margin-left: 30px;
		cursor: pointer;
	}

	.c-auto:hover {
		color: #568dfc;
	}

	.c-auto .iconfont {
		font-size: 28px;
	}

	.c-free {
		display: inline-block;
		position: relative;
		line-height: 150%;
		color: #fff;
		font-weight: 400;
		background-color: #1861f2;
		overflow: hidden;
		padding: 12px 52px 12px 52px;
		font-size: 20px;
		border-radius: 27px;
		cursor: pointer;
	}

	.c-free:hover {
		background-color: #568dfc;
	}

	.operation {
		width: 100%;
	}

	.o-b-b {
		width: 25%;
		padding: 0 24px;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		cursor: pointer
	}

	.o-b-b img {
		width: 78px;
		height: 104px;
		border-radius: 4px;
	}

	.o-title {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		line-height: 24px;
		font-weight: 600;
		font-size: 15px
	}

	.o-down {
		display: flex;
	}

	.o-d-left {
		line-height: 19.5px;
		font-size: 13px;
		color: #676b73
	}

	.o-d-all {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 166px;
		height: 104px;
	}

	.o-d-right {
		display: flex;
		align-items: center;
		padding: 4px 12px;
		margin-right: auto;
		overflow: hidden;
		line-height: 150%;
		color: #630;
		font-size: 13px;
		background-color: #ffe6cc;
		border-radius: 14px;
	}

	.o-d-right:hover {
		background-color: #ffce96;
	}

	.m-homePage-trust-cnt {
		margin: 0 auto;
		width: 1200px;
		text-align: center;
		margin-top: 80px;

	}

	.toger {
		margin: 20px 0 40px;
		width: 1200px
	}

	.m-homePage-scene-cnt {
		margin: 0 auto;
		width: 1200px;
		text-align: center;
		margin-top: 80px;


	}


}
@keyframes myf {
	from {
	 background-color: transparent;

	}
	to{
	 background-color: white;
	 border-bottom:1px solid rgb(232, 232, 232);
	}
}
@keyframes my {
	from {
		background-color: white;
	}
	to{
		background-color: transparent;
	}
}
.headall:hover {
	 background-color: white;
	 animation: myf 0.6s;
     -webkit-animation: myf 0.6s;
	 border-bottom:1px solid rgb(232, 232, 232);
}
</style>